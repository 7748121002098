import React from 'react';
// import { useState } from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
// import { makeStyles } from '@mui/material';

const FancyCard = () => {

    // TODO: Add on mouse hover a zoom to the cards like: https://stackoverflow.com/questions/64080401/how-to-enlarge-card-size-on-hovering-over-it-in-material-ui
    // const useStyles = makeStyles({
    //     root: {
    //         maxWidth: 310,
    //         transition: "transform 0.15s ease-in-out"
    //     },
    //     cardHovered: {
    //         transform: "scale3d(1.05, 1.05, 1)"
    //     }
    // });

    // const classes = useStyles();
    // const [state, setState] = useState({
    //     raised: false,
    //     shadow: 1,
    // })

    return (
        <Card 
        style={{ borderRadius: '1rem' }}
            // className={classes?.root}
            // classes={{ root: state.raised ? classes?.cardHovered : "" }}
            // onMouseOver={() => setState({ raised: true, shadow: 3 })}
            // onMouseOut={() => setState({ raised: false, shadow: 1 })}
            // raised={state.raised} zdepth={state.shadow}
            >
            <CardContent>
                <Typography variant='h4'>Visionen</Typography>
                <Typography variant='body1'>Visionen</Typography>
                <CardMedia
                    component="img"
                    height="194"
                    image="https://mui.com/static/images/cards/paella.jpg"
                    alt="Paella dish"
                />
            </CardContent>
        </Card>
    )
}

export default FancyCard;